<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>MY {{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>My {{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link
                :to="{
                  name: 'Control message',
                  params: { type: message_type },
                }"
                >My {{ this.messagetype.title_jp }}一覧</router-link
              >
            </li>
            <li><span>新規投稿</span></li>
          </ul>
        </div>
        <Form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit="register"
          v-slot="{ errors }"
        >
          <div class="content">
            <div class="innar">
              <div class="form_outwrap">
                <div v-if="messagecolumn['photolog']" class="post_img_wrap">
                  <img id="photolog_img" src="" style="display: none" />
                </div>
                <div v-if="messagecolumn['photolog']" class="t_l m_b40">
                  <div class="camera_btn" id="post_img_area">
                    <label>
                      <Field
                        name="photolog"
                        type="file"
                        class="input-file form-control post_img-file"
                        :class="{ 'is-invalid': errors.photolog }"
                        v-model="cretemessage.photolog"
                        :rules="isRequiredphoto"
                        accept="image/*"
                      />

                      <!-- <input type="file" accept="image/*" capture="camera"> -->
                      <span>画像を選択</span>
                    </label>
                  </div>
                  <ErrorMessage class="each_msg" name="photolog" />
                </div>

                <div
                  v-if="
                    messagecolumn['tags'] &&
                    message_type != 'column' &&
                    message_type != 'photolog'
                  "
                  class="form_outwrap"
                >
                  <p class="ttl_03">{{ messagecolumn["tags"] }}</p>
                  <div
                    v-for="taglist in this.taglists.slice()"
                    :key="taglist.id"
                    class="wrap m_r70"
                  >
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="tags"
                          type="checkbox"
                          class="chkbox"
                          v-model="cretemessage.tags"
                          v-bind:value="taglist.id"
                        /><span></span
                        ><span class="chktxt">{{ taglist.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div v-if="messagecolumn['thanks_type']" class="form_outwrap">
                  <p class="ttl_03">
                    {{ messagecolumn["thanks_type"] }}

                    <ErrorMessage class="each_msg" name="thanks_type" />
                  </p>
                  <div class="wrap m_r70">
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="thanks_type"
                          type="checkbox"
                          class="chkbox"
                          v-model="cretemessage.thanks_type"
                          :rules="isRequiredThanksType"
                          value="サンクス"
                        /><span></span><span class="chktxt">サンクス</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap m_r70">
                    <div class="in_wrap">
                      <label class="chk_wrap">
                        <Field
                          name="thanks_type"
                          type="checkbox"
                          class="chkbox"
                          v-model="cretemessage.thanks_type"
                          :rules="isRequiredThanksType"
                          value="ホメロン"
                        /><span></span><span class="chktxt">ホメロン</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div v-if="messagecolumn['sdgs_type']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["sdgs_type"]
                    }}<ErrorMessage class="each_msg1" name="sdgs_type" />
                  </p>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="sdgs_type"
                        as="select"
                        v-model="cretemessage.sdgs_type"
                        :rules="isRequired"
                      >
                        <option value="2.飢餓をゼロ">2.飢餓をゼロ</option>
                        <option value="3.すべての人に健康と福祉を">
                          3.すべての人に健康と福祉を
                        </option>
                        <option value="6.安全な水とトイレを世界中に">
                          6.安全な水とトイレを世界中に
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>

                <div
                  v-if="messagecolumn['sdgs_option']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["sdgs_option"] }}
                  </p>
                  <div class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="select_wrap attached_name_btn">
                        <Field
                          required=""
                          name="sdgs_option"
                          as="select"
                          v-model="cretemessage.sdgs_option"
                          @change="changeYear($event)"
                        >
                          <option value="2022">49期（2022)</option>
                          <option value="2023">50期（2023)</option>
                          <option value="2024">51期（2024)</option>
                          <option value="2025">52期（2025)</option>
                          <option value="2026">53期（2026)</option>
                        </Field>
                      </div>
                      <div
                        class="select_wrap attached_fire_btn"
                        style="display: none"
                      >
                        <select
                          name="list_type_select"
                          v-model="cretemessage.seletectedMonth"
                          @change="changeMonth($event)"
                        >
                          <option value="">未設定</option>

                          <option value="通年" :data-year="currentYear">
                            通年
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>

                          <option
                            value="10"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            10月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>
                          <option
                            value="11"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            11月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>
                          <option
                            value="12"
                            :data-year="
                              currentYear - 1 != '' && currentYear != 0
                                ? currentYear - 1
                                : ''
                            "
                          >
                            12月
                            {{
                              currentYear - 1 != "" && currentYear != 0
                                ? "(" + (currentYear - 1) + ")"
                                : ""
                            }}
                          </option>

                          <option value="1" :data-year="currentYear">
                            1月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="2" :data-year="currentYear">
                            2月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="3" :data-year="currentYear">
                            3月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="4" :data-year="currentYear">
                            4月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="5" :data-year="currentYear">
                            5月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="6" :data-year="currentYear">
                            6月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="7" :data-year="currentYear">
                            7月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="8" :data-year="currentYear">
                            8月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                          <option value="9" :data-year="currentYear">
                            9月
                            {{
                              currentYear != "" ? "(" + currentYear + ")" : ""
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="messagecolumn['title']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["title"] }}
                    <ErrorMessage class="each_msg" name="title" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.title }"
                      v-model="cretemessage.title"
                      :rules="isRequired"
                    />
                  </div>
                </div>
                <div v-if="messagecolumn['content']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["content"]
                    }}<ErrorMessage class="each_msg" name="content" />
                  </p>
                  <div class="in_wrap">
                    <div v-if="message_type == 'photolog'">
                      <textarea
                        v-model="cretemessage.content"
                        cols="30"
                        rows="10"
                        placeholder="本文を入力してください"
                      ></textarea>
                      <Field
                        name="content"
                        type="hidden"
                        class="form-control"
                        placeholder="本文を入力してください"
                        :class="{ 'is-invalid': errors.content }"
                        v-model="cretemessage.content"
                        :rules="isRequired"
                      />
                    </div>
                    <div v-else>
                      <ckeditor
                        :editor="editor"
                        v-model="cretemessage.content"
                        :config="editorConfig"
                        @input="onEditorInput"
                        @focus="onEditorFocus"
                        @blur="onEditorBlur"
                        @ready="onEditorReady"
                      ></ckeditor>
                      <Field
                        name="content"
                        type="hidden"
                        class="form-control"
                        placeholder="本文を入力してください"
                        :class="{ 'is-invalid': errors.content }"
                        v-model="cretemessage.content"
                        :rules="isRequired"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="messagecolumn['content_1']" class="wrap wid_100per">
                  <p class="ttl_03">
                    {{ messagecolumn["content_1"] }}
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="cretemessage.content_1"
                      :config="editorConfig"
                      @input="onEditorInput"
                      @focus="onEditorFocus"
                      @ready="onEditorReady"
                      @blur="onEditorBlur"
                    ></ckeditor>
                    <Field
                      name="content_1"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      v-model="cretemessage.content_1"
                    />
                  </div>
                </div>

                <div v-if="messagecolumn['content_2']" class="wrap wid_100per" style="display:none">
                  <p class="ttl_03">
                    {{ messagecolumn["content_2"] }}
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="cretemessage.content_2"
                      :config="editorConfig"
                      @input="onEditorInput"
                      @focus="onEditorFocus"
                      @ready="onEditorReady"
                      @blur="onEditorBlur"
                    ></ckeditor>
                    <Field
                      name="content_2"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      v-model="cretemessage.content_2"
                    />
                  </div>
                </div>
                <!-- <div v-if="messagecolumn['status']" class="wrap wid_100per">
								<p class="ttl_03">{{messagecolumn['status']}} <ErrorMessage class="each_msg" name="status" /></p>
								<div class="in_wrap">
									<div class="select_wrap">
									<Field
										required=""
										name="status"
										as="select"
										v-model="cretemessage.status"
										:rules="isRequired"
									>
										<option value="下書き">下書き</option>
										<option value="公開">公開</option>
									</Field>
									</div>
								</div>
							</div>	 -->
                <div
                  v-if="messagecolumn['published_at']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["published_at"] }}
                    <ErrorMessage class="each_msg" name="published_at" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="cretemessage.published_at"
                    />
                  </div>
                </div>
                <div
                  v-if="messagecolumn['attached_file']"
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["attached_file"] }}
                    <span
                      class=""
                      id="fileSize"
                      style="font-size: 1.4rem; margin-left: 0.5em"
                      >※アップロードできるファイルはpdfファイルです。</span
                    >
                    <ErrorMessage class="each_msg" name="attached_file" />
                  </p>
                  <div class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="attached_name_btn">
                        <!--
											<Field
												name="attached_file_name"
												type="text"
												class="form-control"
												:class="{ 'is-invalid': errors.attached_file_name }"
												v-model="cretemessage.attached_file_name[0]"
											/>
											-->
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <label>
                          <!--
												<Field
													name="attached_file"
													type="file"
													class="form-control"
													:class="{ 'is-invalid': errors.attached_file }"
													v-model="cretemessage.attached_file"
												/>
												-->
                          <input
                            type="file"
                            name="rel_doc[]"
                            class="input-file form-control"
                            accept=".pdf"
                          />
                          <span class="file_name">添付ファイル</span>
                        </label>
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                          data-target=""
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                      <div class="fileDisplayName"></div>
                    </div>
                  </div>
                </div>

                <div v-if="message_type == 'thanks'" class="wrap wid_100per">
                  <p class="ttl_03">
                    部門 <ErrorMessage class="each_msg" name="thanks_group" />
                  </p>
                  <div class="in_wrap">
                    <div class="select_wrap" style="width: 260px">
                      <Field
                        required=""
                        name="thanks_group"
                        as="select"
                        v-model="cretemessage.thanks_group"
                        @change="GrouponChange()"
                      >
                        <option
                          v-for="group_list in this.all_group.slice()"
                          v-bind:value="group_list.id"
                          :key="group_list.id"
                        >
                          {{ group_list.name }}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>

                <!-- <div v-if="messagecolumn['group']" class="wrap wid_100per">
								<p class="ttl_03">{{messagecolumn['group']}} <ErrorMessage class="each_msg" name="group"/></p>
								<div class="in_wrap">
									<Field
										name="group"
										type="text" readonly
										class="form-control"
										:class="{ 'is-invalid': errors.group }"
										v-model="cretemessage.group"
									/>
								</div>
							</div> -->

                <div
                  v-if="
                    messagecolumn['target_ids'] &&
                    userlists &&
                    message_type != 'news' &&
                    message_type != 'photolog' &&
                    message_type != 'column' &&
                    approval_pass == false &&
                    is_approval_required==true
                  "
                  class="wrap wid_100per"
                >
                  <p class="ttl_03">
                    {{ messagecolumn["target_ids"] }}
                    <ErrorMessage class="each_msg" name="target_ids" />
                  </p>
                  <div class="in_wrap">
                    <div
                      v-if="
                        messagecolumn['status_list'] == '投稿' &&
                        message_type != 'daily'
                      "
                      class="select_wrap multi"
                      style="width: 260px"
                    >
                      <div class="searchInput">
                        <input
                          type="text"
                          @keyup="searchGroupUser($event)"
                          placeholder="ユーザーを絞り込み"
                        />
                      </div>
                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="cretemessage.target_ids"
                        v-if="message_type != 'daily'"
                        :rules="isRequiredArr"
                        multiple
                      >
                        <option
                          v-for="userlist in this.userlists.slice()"
                          v-bind:value="userlist.id"
                          :key="userlist.id"
                        >
                          {{ userlist.last_name }}{{ userlist.first_name }}
                          {{ userlist.role ? " (" + userlist.role + ")" : "" }}
                        </option>
                      </Field>

                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="cretemessage.target_ids"
                        v-if="message_type == 'daily'"
                        multiple
                      >
                        <option
                          v-for="userlist in this.userlists.slice()"
                          v-bind:value="userlist.id"
                          :key="userlist.id"
                        >
                          {{ userlist.last_name }}{{ userlist.first_name }}
                          {{ userlist.role ? " (" + userlist.role + ")" : "" }}
                        </option>
                      </Field>
                    </div>
                    <div v-else class="select_wrap" style="width: 260px">
                      <Field
                        required=""
                        name="target_ids"
                        as="select"
                        v-model="cretemessage.target_ids"
                        :rules="isRequiredArr"
                      >
                        <option disabled>未設定</option>
                        <option
                          v-for="userlist in this.groupUsers.slice()"
                          v-bind:value="userlist.id"
                          :key="userlist.id"
                        >
                          {{ userlist.last_name }} {{ userlist.first_name }}
                          {{ userlist.role ? " (" + userlist.role + ")" : "" }}
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="messagecolumn['public']" class="form_outwrap">
								<p class="ttl_03">{{messagecolumn['public']}} <ErrorMessage class="each_msg" name="public" /></p>
								<div class="wrap m_r70">
									<div class="in_wrap">
										<label class="chk_wrap">
											<Field
											name="public"
											type="radio"
											class="radiobox"
											v-model="cretemessage.public"
											value='非公開'
											/><span></span><span class="radiotxt">非公開</span>
										</label>
									</div>
								</div>
								<div class="wrap m_r70">
									<div class="in_wrap">
										<label class="chk_wrap">
											<Field
											name="public"
											type="radio"
											class="radiobox"
											v-model="cretemessage.public"
											value='一般公開'
											/><span></span><span class="radiotxt">一般公開</span>
										</label>
									</div>
								</div>
							</div>	 -->

              <div class="t_c">
                <div class="btn_large">
                  <button
                    v-if="
                      message_type == 'column' ||
                      message_type == 'photolog' ||
                      message_type == 'sdgs' ||
                      message_type == 'challenge' ||
                      message_type == 'idea' ||
                      message_type == 'news'
                    "
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    承認申請
                  </button>
                  <button
                    v-else
                    :disabled="processing"
                    type="submit"
                    class="btn btn-primary"
                  >
                    送信
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
  <template id="item_doc_Template">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </template>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import imageCompression from "browser-image-compression";
import Groups from "../../../../apis/Groups";
import moment from "moment";

export default {
  name: "message",
  data() {
    return {
      approval_pass: false,
      userlists: null,
      target_user_ids: null,
      taglists: "",
      thanks_group: this.$User.group ? this.$User.group.id : null,
      // thanks_group:  this.$User.group.id , 
      // MFUX-1444 お知らせ一覧で投稿者の部門がない時のローディングイメージをなくす
      all_group: "",
      messages: [],
      cretemessage: {
        target_ids: "未設定",
        from_type: "users",
        from_id: this.$User.id,
        type: this.$route.params.type,
        content_type: "HTML",
        thanks_type: [],
        public: "非公開",
        attached_file_name: [],
        published_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        sdgs_option: new Date().getFullYear(),
        photolog: "",
        seletectedMonth: "",
      },
      attached_file: [],
      loader: false,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      processing: false,
      getAllGroupByChildId: [],
      profileImg: false,
      currentYear: +new Date().getFullYear(),
      searchGroupId: this.$User.group ? this.$User.group.id : null,
      // searchGroupId:  this.$User.group.id ,
      //for non department user
      // MFUX-1444  お知らせ一覧で投稿者の部門がない時のローディングイメージをなくす
      groupUsers: [],
      is_approval_required:true
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  watch: {
    $route(to, from) {
      this.resetFields();
      this.message_type = this.$route.params.type;
      
    
      this.messagecolumn = MessageColumn[this.message_type];
      this.messagetype = MessageType[this.message_type];
      this.cretemessage["type"] = this.$route.params.type;
      //承認不要チェック
      if (
        this.$User.group &&
      (this.message_type == "column" || this.message_type == "photolog")
      ) {
        if (this.$User.group.name == "経営企画室") {
          this.approval_pass = true;
        }
        if (
          this.$User.group.group_role == "部長執行役員" ||
          this.$User.group.group_role == "部長"
        ) {
          this.approval_pass = true;
        }
      }
     
      // if(this.messagecolumn['target_ids'] && this.message_type != 'daily'){
      // 	this.cretemessage.target_type = "users";
      // }
      if (this.messagecolumn["status_list"] == "投稿") {
        this.cretemessage.status = "公開";
      } else if (this.messagecolumn["status_list"] == "承認") {
        if (this.approval_pass == false) {
          this.cretemessage.status = "下書き";
        } else if (this.approval_pass == true) {
          if (this.message_type != "news") {
            this.cretemessage.status = "公開";
          } else {
            this.cretemessage.status = "下書き";
          }
        }
      }

      if (this.messagecolumn["group"] && this.$User.group != null) {
        if (this.$User.group.parent_id != null) {
          user
            .get_group(this.$User.group.parent_id)
            .then((response) => {
              if (response.data.data.length > 0) {
                this.cretemessage.group = response.data.data[0].name;
              }
            })
            .catch((error) => {
              console.log(error);
              this.$router.push({ name: "Front Error Catch" });
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.cretemessage.group = this.$User.group.name;
        }
      }
      if (this.messagecolumn["tag_list"]) {
        this.getTagLists(this.messagecolumn["tag_list"]);
      }
      if (this.message_type == "thanks") {
        this.getAllGroup();
      } else {
        this.getUserLists();
      }
      $(".menu_list_wrap").removeClass("show");
    },
    userlists(val) {
      this.userlists = val;
    },
    cretemessage(val) {
      console.log("val");
      console.log(val);

    },
    
  },

  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
    $(function () {
      $("div.delete_task_link").each(function (i) {
        $(this).attr("data-target", i + 1);
      });
    });
    $(document).on("click", ".add_task_link ", function () {
      $("div.delete_task_link").each(function (i) {
        $(this).attr("data-target", i + 1);
      });
    });
    $(function () {
      $(".delete_task_link")
        .data("target", "1")
        .on("click", function () {
          $(this).closest(".attached_name_btn").children(".file_name").empty();
          $(this)
            .closest(".attached_action_btn")
            .prev(".attached_fire_btn")
            .find(".input-file.form-control")
            .val("");
          $(this)
            .closest(".attached_action_btn")
            .prev(".attached_fire_btn")
            .find(".file_name")
            .text("添付ファイル");
          $(this)
            .parents(".attached_wrap")
            .children(".attached_name_btn")
            .find(".fileName.form-control")
            .val("");
          var $itembox1 = $(this).closest(".attached_wrap,.new_item");
          var htmlString = $("#item_doc_Template")
            .html()
            .children(".attached_action_btn")
            .find(".delete_task_link")
            .addClass("a")
            .attr("data-target", "1");
          $itembox1.after(htmlString);

          // var $el = $(e.currentTarget).not('[data-target="1"]');
          var $row = $(this).closest(".attached_wrap,.new_item");
          $row.remove();
        });
    });
  },
  created() {
    this.cretemessage.photolog = "";
    this.message_type = this.$route.params.type;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
 
    //承認不要チェック
    if (
      // MFUX-1444  お知らせ一覧で投稿者の部門がない時のローディングイメージをなくす
      this.$User.group &&
      (this.message_type == "column" || this.message_type == "photolog")
    ) {
      if (this.$User.group.name == "経営企画室") {
        this.approval_pass = true;
      }

      if (
        this.$User.group.group_role == "部長執行役員" ||
        this.$User.group.group_role == "部長"
      ) {
        this.approval_pass = true;
      }
    }

//hide approver for sdgs and challenge
//code added by Mariyanandh on 18-01-23

if(this.message_type=='sdgs' || this.message_type=='challenge')
  {
    switch(this.$User.group.group_role) 
        {
          case '部長':
          case '取締役会長':
          case '取締役副社長':
          case '代表取締役社長':
          case '取締役':
          case '部長執行役員':
          case '専務取締役':
            this.is_approval_required=false;
          break;
        
          default:
            // code block
        } 
  }
   
    // if(this.messagecolumn['target_ids'] && this.message_type != 'daily'){
    // 	this.cretemessage.target_type = "users";
    // }
    if (this.messagecolumn["status_list"] == "投稿") {
      this.cretemessage.status = "公開";
    } else if (this.messagecolumn["status_list"] == "承認") {
      if (this.approval_pass == false) {
        this.cretemessage.status = "下書き";
      } else if (this.approval_pass == true) {
        if (this.message_type != "news") {
          this.cretemessage.status = "公開";
        } else {
          this.cretemessage.status = "下書き";
        }
      }
    }

    if (this.messagecolumn["group"] && this.$User.group != null) {
      if (this.$User.group.parent_id != null) {
        user
          .get_group(this.$User.group.parent_id)
          .then((response) => {
         
            if (response.data.data.length > 0) {
              this.cretemessage.group = response.data.data[0].name;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.cretemessage.group = this.$User.group.name;
      }
    }
    if (this.messagecolumn["tag_list"]) {
      this.getTagLists(this.messagecolumn["tag_list"]);
    }
    if (this.message_type == "thanks") {
      this.getAllGroup();
    } else {
      this.getUserLists();
    }

    if (
      // MFUX-1444  お知らせ一覧で投稿者の部門がない時のローディングイメージをなくす
      this.$User.group &&
      this.message_type != "idea" &&
      this.message_type != "sdgs" &&
      this.message_type != "daily" &&
      this.message_type != "challenge"
    ) {

     
      Groups.getGroupUsersByGroupId(
        this.$User.group.id,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
        this.userlists = response.data.data;
      });
    }

    this.profileImgApi();

   
    
  },
  mounted() {
    let vueThis = this;

    document.body.className = "page_control";

    vueThis.message_type = vueThis.$route.params.type;

    this.$nextTick(function () {
      $(document).ready(function () {
        var not = 0;
        $(document).on("change", ".post_img-file", function (e) {
          if (!this.files.length) {
            return;
          }

          var $files = $(this).prop("files");

          var file = $files[0];
          var fr = new FileReader();

          fr.onload = function (e) {
            var src = e.target.result;
            let img = document.getElementById("photolog_img");
            img.src = src;
            $("#photolog_img").css("display", "block");
          };

          fr.readAsDataURL(file);

          $("#preview").css("display", "block");
        });
      });

      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });

    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");

      var $filesSelectName = $el
        .closest(".attached_wrap,.new_item")
        .find(".file_name");

      if (e.target.files[0].size > 3000000) {
        $("#fileSize").text("2 mb以下のファイルを指定してください。");
        $("#fileSize").addClass("each_msg");
        return;
      } else {
        $("#fileSize").text("※アップロードできるファイルはpdfファイルです。");
        $("#fileSize").removeClass("each_msg");
      }

      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el
        .closest(".attached_wrap,.new_item")
        .find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#item_doc_Template").html();
      $itembox.after(htmlString);
    });

    $(document).on("click", ".delete_task_link", function (e) {
      // 最初のマイナスボタンはクリアの動き
      var $el = $(e.currentTarget).not('[data-target="1"]');
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
    $(document).on("click", "button", function () {
      //バリデートを見つけて遷移
      var msg = function () {
        if ($(".each_msg").length) {
          const destination = -100;
          const speed = 300;
          let position = $(".each_msg").offset().top + destination;
          $("html").animate({ scrollTop: position }, speed, "linear");
          return false;
        }
      };
      setTimeout(msg, 100);
    });
  },
  methods: {
    onEditorInput(data, event, editor) {
      editor.editing.view.document.on(
        "enter",
        (evt, data) => {
          if (data.isSoft) {
            editor.execute("enter");
          } else {
            editor.execute("shiftEnter");
          }

          data.preventDefault();
          evt.stop();
          editor.editing.view.scrollToTheSelection();
        },
        { priority: "high" }
      );
    },
    onEditorFocus(event, editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },
    onEditorBlur(event, editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";

        setTimeout(() => {
          editor.ui.view.editable.element.style.height = "170px";
        }, 10);
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },

    onEditorReady(editor) {
      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        editor.ui.view.editable.element.style.height = "170px";
      } else {
        editor.ui.view.editable.element.style.height = "450px";
      }
    },
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    isRequired(value, name) {
      if (value) {
        return true;
      }
      var field_name = this.messagecolumn[name.field];

      return field_name + "は必須です";
    },
    isRequiredThanksType(value, name) {
      var field_name = this.messagecolumn[name.field];

      if (value.length === 0 || value.length === "0") {
        return field_name + "は必須です";
      }
      return true;
    },
    isRequiredArr(value, name) {
      var field_name = this.messagecolumn[name.field];

      if (value == undefined || value == "" || value == "未設定") {
        return field_name + "は必須です";
      }

      if (value != "") {
        return true;
      } else {
        return field_name + "は必須です";
      }

      // if (value.length == 0) {
      //   return field_name + "は必須です";
      // }
    },
    isRequiredphoto(value, name) {
      var error_message = "";
      var field_name = this.messagecolumn[name.field];
      if (!value) {
        return field_name + "は必須です";
      } 
        // ファイルサイズチェック
      if(value[0].size > process.env.VUE_APP_MAX_UPLOAD_FILE_SIZE_MEGABYTE){
        error_message = 'ファイルサイズは3MB以下にしてください';
      }
      
      if (value[0].type != "image/jpeg" && value[0].type != "image/png" && value[0].type != "image/jpeg") {
        error_message += "アップロードできるファイルの形式はjpg,png,jpegです";
      }
      
      if (error_message == "") {
        return true;
      }
      else {
        return error_message;
      }
    },
    enable: function () {
      this.processing = false;
    },
    register() {
      this.processing = true;

      setTimeout(this.enable, 3000);

      if (this.cretemessage.target_ids == "未設定") {
        this.cretemessage.target_ids = [];
      }
      if (this.messagecolumn["target_ids"]) {
        if (this.cretemessage.target_ids.length) {
          this.cretemessage.target_type = "users";
        }
      }

      if (!this.messagecolumn["title"]) {
        this.cretemessage.title = this.messagecolumn["title_str"];
      }

      if (this.messagecolumn["status_list"] == "承認") {
        if (!Array.isArray(this.cretemessage.target_ids)) {
          var target_id = this.cretemessage.target_ids;
          this.cretemessage.target_ids = [];
          this.cretemessage.target_ids[0] = target_id;
        }
      }

      // Remove for publish_at set default value MFUX-991 - フロント：メッセージ系の編集画面でステータス編集が不要

      if (this.message_type == "news") {
        // MFUX-1302  お知らせが作成するとき、公開日を選択したら、サーバーエラーページに移動します
        // FOR NEWS CREATION PUBLISHED AT DATE FORMAT

        if (
          this.messagecolumn["published_at"] &&
          this.cretemessage.published_at
        ) {
          this.cretemessage.published_at =
            this.cretemessage.published_at.replace("T", " ");
          this.cretemessage.published_at =
            this.cretemessage.published_at.substring(0, 16);
          var p_date = (this.cretemessage.published_at += ":00");
          this.cretemessage.published_at = p_date.substr(0, 19);
        }
      }

     

      if (
        this.message_type == "sdgs" ||
        this.message_type == "challenge" ||
        this.message_type == "idea" ||
        this.message_type == "news"
      ) {
        this.cretemessage.status = "申請中";
      }
      if (
        this.is_approval_required == false
      ) {
        this.cretemessage.status = "公開";
      }

      if(this.cretemessage.target_ids == this.cretemessage.from_id){
        this.cretemessage.target_type = null;
        this.cretemessage.status = '承認済';
      }

      if (this.messagecolumn["attached_file"]) {
        for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
          let $el = $(".attached_wrap:eq(" + i + ")");

          let $fileName = $el.find(".fileName");

          let $fileData = $el.find(".input-file");

          if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
            if ($fileName.val() == "") {
              alert("資料名を入力してください");
              this.attached_file = [];
              return;
            }
            if ($fileData[0].files.length == 0) {
              alert("ファイルを選択してください");
              this.attached_file = [];
              return;
            }
            if (file && permit_type.indexOf(file.type) == -1) {
              alert("アップロードできるファイルの形式はpdfです");
              this.attached_file = [];
              return;
            }

            let fileObject = new FormData();

            fileObject.append("key", "attached_file");
            fileObject.append("display_name", $fileName.val());
            fileObject.append("uploadfile", $fileData[0].files[0]);

            this.attached_file.push(fileObject);

            var file = $fileData[0].files[0];
            var permit_type = ["application/pdf"];
          }
        }
      }
      $("#js-loader").fadeIn();

      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        this.cretemessage.content = JSON.stringify({
          content: this.cretemessage.content,
          content_1: this.cretemessage.content_1,
          // content_2: this.cretemessage.content_2,
          option: this.cretemessage.sdgs_option,
          month: this.cretemessage.seletectedMonth,
        });
      }

      if (this.message_type == "daily") {
        var idea_target_id = this.cretemessage.target_ids;
        this.cretemessage.target_ids = null;
        this.cretemessage.target_ids = [idea_target_id];
      } else {
        this.cretemessage.target_ids = this.cretemessage.target_ids.filter(
          (item) => {
            return item != this.$User.id;
          }
        );
      }

      if (this.approval_pass == false) {
        if (this.message_type == "column" || this.message_type == "photolog") {
          this.cretemessage.status = "申請中";
          this.cretemessage.tags = [];
        }
      }

      let fileObject = new FormData();
      
      fileObject.append("key", "photolog");
      fileObject.append("display_name", "photo_log");
      fileObject.append("uploadfile", this.cretemessage.photolog[0]);

      fileObject.set("data",JSON.stringify(this.cretemessage));
      if (this.message_type != 'photolog') {
        message
        .register(this.cretemessage)
        .then(
          (response) => {
            $("#js-loader").fadeIn();

            if (this.cretemessage.sdgs_type) {
              this.postMessageMeta(
                "sdgs_type",
                this.cretemessage.sdgs_type,
                "SDGsタイプ",
                response.data.data.id
              );
            }
            if (this.cretemessage.group) {
              this.postMessageMeta(
                "group",
                this.cretemessage.group,
                "部門",
                response.data.data.id
              );
            }
            if (this.messagecolumn["public"] && this.cretemessage.public) {
              this.postMessageMeta(
                "public",
                this.cretemessage.public,
                "一般公開設定",
                response.data.data.id
              );
            }
            if (this.cretemessage.thanks_type) {
              this.cretemessage.thanks_type.forEach((thanks_type) => {
                this.postMessageMeta(
                  "thanks_type",
                  thanks_type,
                  "サンクスのタイプ",
                  response.data.data.id
                );
              });
            }
            /*
          if(this.cretemessage.attached_file){
            this.postMessageAttachedfile('attached_file',response.data.data.id);
          }
          */
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            $("#js-loader").fadeOut();
           
            this.$router.push({
              name: "Control messageshow",
              params: { type: this.message_type, id: response.data.data.id },
            });
          })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    }
    if (this.message_type == 'photolog')
    {
      message
        .registerPhotolog(fileObject)
        .then(
          (response) => {
            $("#js-loader").fadeIn();

            if (this.cretemessage.sdgs_type) {
              this.postMessageMeta(
                "sdgs_type",
                this.cretemessage.sdgs_type,
                "SDGsタイプ",
                response.data.data.id
              );
            }
            if (this.cretemessage.group) {
              this.postMessageMeta(
                "group",
                this.cretemessage.group,
                "部門",
                response.data.data.id
              );
            }
            if (this.messagecolumn["public"] && this.cretemessage.public) {
              this.postMessageMeta(
                "public",
                this.cretemessage.public,
                "一般公開設定",
                response.data.data.id
              );
            }
            if (this.cretemessage.thanks_type) {
              this.cretemessage.thanks_type.forEach((thanks_type) => {
                this.postMessageMeta(
                  "thanks_type",
                  thanks_type,
                  "サンクスのタイプ",
                  response.data.data.id
                );
              });
            }
            /*
          if(this.cretemessage.attached_file){
            this.postMessageAttachedfile('attached_file',response.data.data.id);
          }
          */
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            $("#js-loader").fadeOut();
           
            if(!response && this.message_type == 'photolog') {
              $("#js-loader").fadeOut();
            this.$swal({
              title: '画像がアップできませんでした。もう一度アップロードしてください。',
              showCancelButton: true,
              confirmButtonText: '新規追加My フォトログ',
              cancelButtonText: 'My フォトログ一覧',
            }).then((result) => {
                if(result.value) {    
                  this.$router.push({
                        name: "Control messagecreate",
                        params: { type: this.message_type },
                  });
                } else {
                  this.$router.push({
                        name: "Control message",
                        params: { type: this.message_type,},
                  });
                }
            })

          }
            this.$router.push({
              name: "Control messageshow",
              params: { type: this.message_type, id: response.data.data.id },
            });
          })
        .catch((error) => {
          if(error && this.message_type == 'photolog') {
            $("#js-loader").fadeOut();
            this.$swal({
              title: '画像がアップできませんでした。もう一度アップロードしてください。',
              showCancelButton: true,
              confirmButtonText: '再新規作成',
              cancelButtonText: '一覧に戻る',
            }).then((result) => {
                if(result.value) {    
                  this.$router.push({
                        name: "Control messagecreate",
                        params: { type: this.message_type },
                  });
                } else {
                  this.$router.push({
                        name: "Control message",
                        params: { type: this.message_type,},
                  });
                }
            })
        }
        })
        .finally(() => {});
    }
    }
    ,
  
    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        message
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },

    async getUserLists() {
      if (this.messagecolumn["status_list"] == "投稿") {
        if (this.message_type != "daily") {
          if (
            this.cretemessage.thanks_group == null &&
            this.$User.group.id == null
          ) {
            user
              .list()
              .then((response) => {
                if (response != null) {
                  this.userlists = response.data.data;
                }
              })
              .catch((error) => {
                console.log(error);
                this.$router.push({ name: "Front Error Catch" });
              })
              .finally(() => {
                this.loader = false;
              });
          } else {
            if (this.cretemessage.thanks_group == null) {
              if (this.$User.group.parent_id == null) {
                this.cretemessage.thanks_group = this.$User.group.id;
              } else {
                this.cretemessage.thanks_group = this.$User.group.parent_id;
              }
            }

            // console.log(this.getAllGroupByChildId);

            let target_user_data = [];

            let allChildIds = this.getAllGroupByChildId
              .filter((item) => {
                return item.parent_id == this.cretemessage.thanks_group;
              })
              .map(function (item) {
                return item.id;
              });

            allChildIds.push(this.cretemessage.thanks_group);

            for (let index = 0; index < allChildIds.length; index++) {
              await user
                .get_group(allChildIds[index])

                .then(async (response) => {
                  if (response != null) {
                    if (response.data.data.group_users.length > 0) {
                      response.data.data.group_users.forEach((user_data) => {
                        user
                          .show(user_data.user_id)
                          .then((response) => {
                            if (response != null) {
                              if (this.message_type == "daily") {
                                if (response.data.data.group) {
                                  if (
                                    response.data.data.group.group_role != null
                                  ) {
                                    target_user_data.push(response.data.data);
                                  }
                                }
                              } else {
                                target_user_data.push(response.data.data);
                              }
                              // TEST
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          })
                          .finally(() => {
                            this.loader = false;
                          });
                      });

                      this.userlists = target_user_data;

                      // if (this.message_type == "daily") {
                      //   this.userlists = target_user_data.filter((item) => {
                      //     console.log(item.group.group_rank);

                      //     return item.group.group_rank != null;
                      //   });
                      // }
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});
            }
          }
        } else {
          //親のグループIDを取得
          var target_group_id_oya_daily = null;
          if (this.$User.group.parent_id) {
            target_group_id_oya_daily = this.$User.group.parent_id;
          } else {
            target_group_id_oya_daily = this.$User.group.id;
          }

          
          Groups.getGroupUsersByGroupId(
            target_group_id_oya_daily,
            this.message_type
          ).then((response) => {
            this.groupUsers = response.data.data;
            this.userlists = response.data.data;
          });
        }
      } else if (this.messagecolumn["status_list"] == "承認") {
        //親のグループIDを取得
        var target_group_id = null;
        var target_group_id_oya = null;

        if (this.$User.group) {
          if (this.$User.group.parent_id) {
            target_group_id = this.$User.group.parent_id;
            target_group_id_oya = this.$User.group.parent_id;
          } else {
            target_group_id_oya = this.$User.group.id;
            target_group_id = this.$User.group.id;
          }
        }
        //console.log(target_group_id);

        //属するグループリスト作成
        var target_group_array = [];
        Groups.list()
          .then((response) => {
            if (response != null) {
              //console.log(response.data.data);
              response.data.data.forEach((ob) => {
                //親のグループ
                if (ob.id == target_group_id) {
                  target_group_array.push(ob);
                }
                //属する課
                if (ob.parent_id == target_group_id) {
                  target_group_array.push(ob);
                }
              });
              var target_user_data = [];
              var target_user_ids = [];
              target_group_array.forEach((under_ob) => {
                user
                  .get_group(under_ob.id)
                  .then((response) => {
                    if (response != null) {
                      if (
                        this.message_type == "idea" ||
                        this.message_type == "sdgs" ||
                        this.message_type == "challenge" ||
                        this.message_type == "daily"
                      ) {
                      Groups.getGroupUsersByGroupId(
                          target_group_id_oya,
                          this.message_type
                        ).then((response) => {
                          this.groupUsers = response.data.data;
                          this.userlists = response.data.data;
                        });
                      } else {
                        if (response.data.data.group_users.length > 0) {
                          response.data.data.group_users.forEach(
                            (user_data) => {
                              if (
                                this.message_type == "photolog" ||
                                this.message_type == "column"
                              ) {
                                if (
                                  user_data.role != null &&
                                  user_data.rank > 299
                                ) {
                                  user
                                    .show(user_data.user_id)
                                    .then((response) => {
                                      if (response != null) {
                                        target_user_data.push(
                                          response.data.data
                                        );
                                        target_user_ids.push(
                                          response.data.data.id
                                        );
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    })
                                    .finally(() => {
                                      this.loader = false;
                                    });
                                }
                                this.cretemessage.target_ids = target_user_ids;
                              } else if (this.message_type == "news") {
                                this.getNewsApproval();
                              } else {
                                if (user_data.role != null) {
                                  user
                                    .show(user_data.user_id)
                                    .then((response) => {
                                      if (response.data) {
                                        this.userlists = response.data.data;
                                        console.log(this.userlists);
                                        target_user_data.push(
                                          response.data.data
                                        );
                                        target_user_ids.push(
                                          response.data.data.id
                                        );
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    })
                                    .finally(() => {
                                      this.loader = false;
                                    });
                                }
                              }
                            }
                          );
                          this.userlists = target_user_data;
                          console.log(this.userlists);
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$router.push({ name: "Front Error Catch" });
                  })
                  .finally(() => {
                    this.loader = false;
                  });
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    getTagLists(tag_array) {
      message
        .tag_list(tag_array)
        .then((response) => {
          if (response != null) {
            this.taglists = response.data.data;
            if (
              this.message_type == "photolog" ||
              this.message_type == "column"
            ) {
              var target = this.taglists.filter((e) => e["name"] === "業務");
              this.cretemessage.tags = [target[0]["id"]];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    getNewsApproval() {
      user
        .get_news_approval()
        .then((response) => {
          if (response != null) {
            // console.log(response.data.data.id);
            // if (response.data.data.id != this.$User.id) {
            //   }
            this.cretemessage.target_ids = response.data.data.id;
            // this.getAllGroupByChildId = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    getAllGroup() {
      user
        .group_list()
        .then((response) => {
          if (response != null) {
            this.all_group = response.data.data;
            this.getAllGroupByChildId = response.data.data;

            this.all_group = response.data.data.filter((item) => {
              return item.parent_id == null && item.name != "開発グループ";
            });

            if (this.message_type == "thanks" || this.message_type == "daily") {
              Groups.getGroupUsersByGroupId(
                this.$User.group.id,
                this.message_type
              ).then((response) => {
                this.userlists = response.data.data;
              });
            } else {
              this.getUserLists();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    GrouponChange() {
      this.searchGroupId = this.cretemessage.thanks_group;

      if (this.message_type == "thanks" || this.message_type == "daily") {
        Groups.getGroupUsersByGroupId(
          this.cretemessage.thanks_group,
          this.message_type
        ).then((response) => {
          this.userlists = response.data.data;
        });
      } else {
        this.getUserLists();
      }
    },
    postMessageMeta(key, value, description, id) {
      var post_json = {
        key: key,
        value: value,
        description: description,
      };
      message
        .register_meta(post_json, id)
        .then(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(error);

            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    changeYear: function (event) {
      if (event.target.value != "") {
        this.currentYear = event.target.value;
      } else {
        this.currentYear = "";
      }
    },
    changeMonth: function (e) {
      this.cretemessage.sdgs_option =
        e.target.options[e.target.options.selectedIndex].dataset.year;

      this.cretemessage.seletectedMonth = e.target.value;

      if (this.cretemessage.sdgs_option == new Date().getFullYear() - 1) {
        this.cretemessage.sdgs_option = new Date().getFullYear();
      }
    },
    searchGroupUser(event) {
      Groups.getSearchGroupUsers(
        this.searchGroupId,
        event.target.value,
        this.message_type
      ).then((response) => {
        this.userlists = response.data.data;
      });
    },
    // setAthesekiUser(e) {
    //   this.cretemessage.target_ids = [];
    //   this.cretemessage.target_ids.push(e.target.value);
    // },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
